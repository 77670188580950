import React from "react"
import { Box, Link, Heading, List, ListItem, Flex } from "@chakra-ui/core"
import { Link as GatsbyLink } from "gatsby"
import Layout from "../components/layout"
import { SectionHeading, Paragraph, Bold } from "../components/ui-elements"
import SEO from "../components/seo"
import VirtualAssistant from "./virtual-assistant"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy - VERIFiSMART" description="Explore our privacy policy: Learn how we protect your personal data. Discover what information we collect, how we use it, and your rights."/>
      <Box py="16">
        <Box color="theme-dark" maxW="containers.xl" mx="auto" my="16" w="100%">
          <SectionHeading>Privacy Policy</SectionHeading>
          <Paragraph>
            This privacy policy is applicable to the website{" "}
            <Link textDecoration="underline" href="www.verifismart.com">
              www.verifismart.com
            </Link>{" "}
            (the “Website”) managed and operated by Legal Tech Innovations Ltd.
          </Paragraph>
          <Paragraph>
            All references to "we/our/us" in this Privacy Policy refer to Legal Tech Innovations Ltd located at 41 Devonshire Street, Ground Floor, London, United Kingdom, W1G 7AJ.
          </Paragraph>
          <Paragraph>
            All references to "you/your" or the “user” refer to a user of the
            Website or a customer of the services offered by VERIFiSMART through
            its platform (“Platform”).
          </Paragraph>
          <Box my="16">
            <Heading size="lg" mb="4">
              Table of contents
            </Heading>
            <Flex flexDir="column">
              <Link href="#personal-data">1. WHAT IS PERSONAL DATA?</Link>
              <Link href="#data-we-collect">
                2. WHAT DATA WE COLLECT, HOW AND WHY?
              </Link>
              <Link href="#who-do-we-share-info-with">
                3. WHO DO WE SHARE THE INFORMATION WITH?
              </Link>
              <Link href="#international-transfers">
                4. INTERNATIONAL TRANSFERS
              </Link>
              <Link href="#personal-info-secure">
                5. KEEPING YOUR PERSONAL INFORMATION SECURE
              </Link>
              <Link href="#processing-personal-data">
                6. BASIS FOR PROCESSING PERSONAL DATA
              </Link>
              <Link href="#retain-the-info">
                7. HOW LONG DO WE RETAIN THE INFORMATION?
              </Link>
              <Link href="#individual-rights">8. YOUR INDIVIDUAL RIGHTS</Link>
              <Link href="#changes-to-privacy-policy">
                9. CHANGES TO THIS PRIVACY POLICY
              </Link>
            </Flex>
          </Box>
          <Box id="personal-data" mt="16">
            <Heading size="lg">1. WHAT IS PERSONAL DATA?</Heading>
            <Paragraph>
              This privacy policy aims to provide you with some information
              about the collection, processing and use of personal data when
              using the Website. We collect, process and use personal data
              exclusively according to this data privacy statement and within
              the scope of statutory authorization. If you need further
              information or have any questions about how we handle data
              privacy, please contact:{" "}
              <Link
                textDecoration="underline"
                href="mailto:info@verifismart.com"
              >
                info@verifismart.com
              </Link>
            </Paragraph>
            <Paragraph>
              Personal data refers to any personally-identifiable information
              relating to an individual. This includes e.g. your name, e-mail
              address, postal address, telephone number or any other
              identification data such as cookies or that you submit relating to
              yourself when registering an account in the Platform or when
              contacting us directly, through the Website or through the
              Platform (“Data”).
            </Paragraph>
            <Paragraph fontWeight="400" textDecoration="underline">
              By using the Website or submitting your details to us, you consent
              to us processing those details for the purposes specified in this
              Privacy Policy.
            </Paragraph>
          </Box>

          <Box id="data-we-collect" mt="16">
            <Heading size="lg">2. WHAT DATA WE COLLECT, HOW AND WHY?</Heading>
            <Heading mt="16" size="md">
              Contact
            </Heading>
            <Paragraph>
              The contact details you submit to us through the Website will be
              used to help us to contact you in relation to your query or
              provide other information that we think you might be interested
              in. This Data will also be used for statistical purposes, to
              improve the Website content, and for updating our internal
              databases.
            </Paragraph>
            <Paragraph>
              You can revoke your consent at any time in writing by post or
              email to us.
            </Paragraph>
            <Heading mt="16" size="md">
              Account Registration
            </Heading>
            <Paragraph>
              The registration details you submit to us through the Platform
              will be used sley for the creation of your account and for the
              generation of registration certificates as/when requested by you
              as part of the VERIFiSMART services. Only you name will be visible
              in the registration certificates as prove of ownership. Any other
              information will only be used by us to keep internal records of
              our customers and accounts created.
            </Paragraph>
            <Paragraph>
              If you contact us through the Platform, after your account has
              been created, we will use your information to communicate and
              provide any information that we think you might be interested in.
            </Paragraph>
            <Heading mt="16" size="md">
              Connection information
            </Heading>
            <Paragraph>
              When you access the Website, particular information is collected
              by us automatically, such as a user’s IP address, the type and
              version of the web browser used, the operating system used, the
              site from where the user is being redirected (if applicable) and
              the time of visiting the Website.
            </Paragraph>
            <Paragraph>
              As a rule, we will analyse these Data statistically in order to
              prevent errors, to improve our content and services, and to
              identify the source of traffic of the Website’s users.
            </Paragraph>
            <Paragraph>
              Only in the event of potential abuse related to the use of the
              Website, we would use the connection information Data to
              facilitate the identification of the individual(s) responsible.
            </Paragraph>
            <Heading mt="16" size="md">
              Cookies & Web Beacons
            </Heading>
            <Paragraph>
              To enhance your experience with the Website, many of our web pages
              will use "cookies."
            </Paragraph>
            <Paragraph>
              Cookies are text files we place in your computer's browser to
              store your preferences. Cookies, by themselves, do not tell us
              your e-mail address or other personally identifiable information
              unless you choose to furnish this information to us by, for
              example, submitting your contact details to us in the ‘Contact Us’
              page. If you choose to submit to us your personally identifiable
              information, this information may be linked to the Data stored in
              the cookie.
            </Paragraph>

            <Paragraph>
              We use cookies to understand the Website usage and to improve the
              content and offerings on the Website. For example, we may cookies
              for analytical purposes, to personalize your experience at our web
              pages (e.g., to recognize you when you return to the Website), or
              to save your password in password-protected areas.
            </Paragraph>
            <Paragraph>
              To change your privacy preferences regarding the use of cookies
              and similar technologies please review the settings available
              through your browser of use. Please note that blocking cookies may
              affect your online experience and may prevent you from enjoying
              the full features offered by the Website.
            </Paragraph>

            <Paragraph>
              For more information on what are cookies, the type of cookies used
              by the Website and your privacy preferences regarding such
              cookies, please see our{" "}
              <Bold
                as={GatsbyLink}
                textDecoration="underline"
                to="/cookies-policy"
              >
                Cookies Policy
              </Bold>
            </Paragraph>
          </Box>

          <Box id="who-do-we-share-info-with" mt="16">
            <Heading size="lg">
              3. WHO DO WE SHARE THE INFORMATION WITH?
            </Heading>
            <Paragraph>
              The Data may be shared with our partners and other participants
              operating on behalf of us, e.g. subcontractors with a view to
              providing services related to the Website. We may also provide
              information to third party service providers to enable us to
              administer the Website.
            </Paragraph>
            <Paragraph>
              We may also disclose Data with a view to meet a legal obligation
              or if required by law or a court order.
            </Paragraph>
            <Paragraph>
              In any of the above cases, we will ensure steps are taken so that
              your rights in relation to your data continue to be protected.
            </Paragraph>
            <Paragraph fontWeight="400">
              For analytical purposes, our Website uses cookies provided by
              Google and we share all data collected by such cookies with
              Google. You can find more information on how Google uses
              information collected from our Website in the{" "}
              <Link
                textDecoration="underline"
                href="https://policies.google.com/technologies/partner-sites"
                isExternal
              >
                Google Policy
              </Link>
            </Paragraph>
          </Box>

          <Box id="international-transfers" mt="16">
            <Heading size="lg">4. INTERNATIONAL TRANSFERS</Heading>
            <Paragraph>
              To deliver services to you or provide information required by you,
              it is sometimes necessary for us to share your personal
              information outside the European Economic Area (EEA), eg:
            </Paragraph>
            <List fontWeight="200">
              <ListItem my="4">
                with your and our service providers located outside the EEA;
              </ListItem>
              <ListItem my="4">if you are based outside the EEA;</ListItem>
            </List>
            <Paragraph>
              These transfers are subject to special rules under European and UK
              data protection laws. 
            </Paragraph>
            <Paragraph>
              The following countries to which we may transfer personal
              information have been assessed by the European Commission as
              providing an adequate level of protection for personal
              information: Andorra. 
            </Paragraph>
            <Paragraph>
              If you would like further information, please contact us.
            </Paragraph>
          </Box>

          <Box id="personal-info-secure" mt="16">
            <Heading size="lg">
              5. KEEPING YOUR PERSONAL INFORMATION SECURE
            </Heading>
            <Paragraph>
              We take appropriate measures, about the nature of the Data
              collected and the inherent risks in processing it, to preserve the
              confidentiality and security of such Data and to prevent loss of,
              or alteration or damage to, the Data or access to it by
              non-authorised third parties.
            </Paragraph>
            <Paragraph>
              We limit access to your personal information to those who have a
              genuine business need to access it. Those processing your
              information will do so only in an authorised manner and are
              subject to a duty of confidentiality.
            </Paragraph>
            <Paragraph>
              Data will be stored on a cloud-based server and processed at our
              web server hubs, and at the offices of the parties to which such
              data may be communicated.
            </Paragraph>
          </Box>

          <Box id="processing-personal-data" mt="16">
            <Heading size="lg">6. BASIS FOR PROCESSING PERSONAL DATA</Heading>
            <Paragraph>
              We will only process your Data by way of your consent. You are
              giving your consent by:
            </Paragraph>
            <List as="ol" fontWeight="200" styleType="upper-roman">
              <ListItem my="4">
                clicking ‘accept’ on the Cookies pop-up notice and/or continuing
                to use the Website,
              </ListItem>
              <ListItem my="4">
                by submitting your contact details to us
              </ListItem>
              <ListItem my="4">
                by registering an account in our Platform.
              </ListItem>
            </List>
            <Paragraph>
              By such consent, you are accepting for the processing of your
              personal data on the terms of this Privacy Policy.
            </Paragraph>
          </Box>

          <Box id="retain-the-info" mt="16">
            <Heading size="lg">
              7. HOW LONG DO WE RETAIN THE INFORMATION?
            </Heading>
            <Paragraph>
              We will keep your personal Data for as long as is necessary: 
            </Paragraph>
            <List styleType="disc" fontWeight="200">
              <ListItem my="4">
                to respond to any questions, complaints or claims made by you or
                on your behalf;
              </ListItem>
              <ListItem my="4">
                to show that we treated you fairly; and
              </ListItem>
              <ListItem my="4">
                to keep company records required by law.
              </ListItem>
            </List>
            <Paragraph>
              We will not retain your personal information for longer than
              necessary for the purposes set out in this Policy. Different
              retention periods apply for different types of personal
              information.
            </Paragraph>
          </Box>

          <Box id="individual-rights" mt="16">
            <Heading size="lg">8. YOUR INDIVIDUAL RIGHTS</Heading>
            <Paragraph>
              You have the following rights in relation to your Data:
            </Paragraph>
            <List styleType="disc" fontWeight="200">
              <ListItem my="4">
                to be informed about how, why and on what basis that information
                is processed;
              </ListItem>
              <ListItem my="4">
                to obtain confirmation that your information is being processed
                and to obtain access to it and certain other information, by
                making a subject access request;
              </ListItem>
              <ListItem my="4">
                to have data corrected if it is inaccurate or incomplete;
              </ListItem>
              <ListItem my="4">
                to have data erased if it is no longer necessary for the purpose
                for which it was originally collected/processed, or if there are
                no overriding legitimate grounds for the processing (this is
                sometimes known as the ‘right to be forgotten’);
              </ListItem>
              <ListItem my="4">
                to restrict the processing of personal information where the
                accuracy of the information is contested, or the processing is
                unlawful (but you do not want the data to be erased), or where
                the we no longer need the personal information but you require
                the data to establish, exercise or defend a legal claim; and
              </ListItem>
              <ListItem my="4">
                to restrict the processing of personal information temporarily
                where you do not think it is accurate or where you have objected
                to the processing.
              </ListItem>
            </List>
            <Paragraph>
              If you wish to exercise any of the above rights, please contact
              us:{" "}
              <Link
                textDecoration="underline"
                href="mailto:info@verifismart.com"
              >
                info@verifismart.com
              </Link>
            </Paragraph>
            <Paragraph>
              Any exercise of the ‘right to be forgotten’ or to restrict the
              processing of certain Data will not affect the lawfulness of any
              processing that had taken place before the exercise of such
              rights.
            </Paragraph>

            <Paragraph>
              Any exercise of the ‘right to be forgotten’ or to restrict the
              processing of certain Data will not affect the lawfulness of any
              processing that had taken place before the exercise of such
              rights.
            </Paragraph>
            <Paragraph>
              We hope that we can resolve any query or concern you may raise
              about our use of your Data.
            </Paragraph>
            <Paragraph fontWeight="400">
              The General Data Protection Regulation also gives you right to
              lodge a complaint with a supervisory authority, in particular in
              the European Union (or European Economic Area) state where you
              work, normally live or where any alleged infringement of data
              protection laws occurred. The supervisory authority in the UK is
              the Information Commissioner who may be contacted
              at https://ico.org.uk/concerns or telephone: 0303 123 1113.
            </Paragraph>
            <Paragraph>
              If there are variances between different languages of this Privacy
              Policy, arising from translation, then the English version shall
              prevail.
            </Paragraph>
          </Box>

          <Box id="changes-to-privacy-policy" mt="16">
            <Heading size="lg">9. CHANGES TO THIS PRIVACY POLICY </Heading>
            <Paragraph>
              This Policy was last updated on 5 September 2020. 
            </Paragraph>
            <Paragraph>
              We may change this Policy from time to time—when we do, we will
              provide notice via the Website. 
            </Paragraph>
            <Paragraph>
              If there are variances between this Privacy Policy and its
              translation to other languages, then the English version shall
              prevail. 
            </Paragraph>
          </Box>
        </Box>
      </Box>
      <VirtualAssistant />
    </Layout>
  )
}

export default PrivacyPolicy
